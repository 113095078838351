
export const getCookie = function getCookie(name:any) {
  const value = `; ${document.cookie}`;
  const parts:any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const deleteCookie = function deleteCookie( name:any, path?:any, domain?:any ) {
  if( getCookie( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

// find and remove from array
export const remove = function removeFromArray(array:any, value:any) {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
};

export const showHideField = function showHideField(
  that: any,
  passValue:any,
  showText: string,
  hideText: string
) {
  if (that.textContent == showText) {
    that.innerText = hideText
  } else {
    that.innerText = showText
  }
  const type = passValue.getAttribute('type') === 'password' ? 'text' : 'password';
  passValue.setAttribute('type', type);
}

// debounce in javascript

export const debounce = function debounce(func:any, wait:any, immediate?:boolean) {
  let timeout:any;
  return function() {
    const context = this, args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

}