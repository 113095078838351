// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
import "@hotwired/turbo-rails";
import 'plugins/timeTracking'
import "cocoon";

import "@fortawesome/fontawesome-free/js/all";
// import Swal from 'sweetalert2'
import axios from 'axios';
import ahoy from "ahoy.js";
import "youtube.js";
import {getCookie} from '../packs/components/Element/Helper'


ahoy.trackAll();
global.$ =  require("jquery")
global.jQuery =  $;
// global.Swal = Swal
global.tzone = require('jstimezonedetect');


getSaveTimezone()
addTimezoneToProfile()

import './bootstrap_custom.js'
import '../stylesheets/application'
// import '../stylesheets/gen_application.scss'
// import './gen_application'
// import '../stylesheets/gen_application'
// import './krowd.js'


function addTimezoneToProfile() {
  var timeZone = tzone.determine().name()
  if (localStorage.tzone != timeZone) {
    axios({
      method: 'post',
      url: '/users/update_timezone',
      data: {tzone: timeZone},
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json',
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      localStorage.setItem("tzone", response.data.timezone);
    })
    .catch(function (response) {
      console.log("Not authorized.");
    });
  }
}


function getSaveTimezone() {
  if (!localStorage.tzone) {
    axios.get("/users/get_timezone")
    .then((response) => {
     localStorage.setItem("tzone", response.data.timezone);
    }).catch((err) => {
      console.log(err);
    });
  }
}


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// Header scroll
$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll) {
    $(".header_area").addClass("navbar_fixed");
  } else {
    $(".header_area").removeClass("navbar_fixed");
  }
});

function showAvatar(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#previewAvatar')
        .attr('src', e.target.result)
        .width(150)
        .height(200);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

$( document ).on('turbo:load', function() {
  $('#user_company_attributes_avatar, #user_avatar, #krowd_avatar').on('change', function() {
    showAvatar(this);
  })
  $('#sidebarToggle').on('click', function() {
    var sideBar = document.getElementById("mobileSideBar");
    sideBar.classList.toggle("displayMenu");
  })

})

global.getCookie = getCookie

require("trix")
require("@rails/actiontext")


