import TimeMe from "timeme.js";
import axios from 'axios';

// global.TimeMe = TimeMe
TimeMe.initialize({
  currentPageName: "Workrowd", // current page
  idleTimeoutInSeconds: 30
});


window.onbeforeunload = function (event) {
  var workrowd = JSON.parse( localStorage.getItem("workrowd_server") );
  var timeSpentOnPage = TimeMe.getTimeOnCurrentPageInSeconds();
  if (workrowd != null && workrowd.employeeId) {
    axios({
      method: 'post',
      url: '/users/track_time',
      data: {time: timeSpentOnPage, user_id: workrowd.currentUser, employee_id: workrowd.employeeId},
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json',
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
    })
    .catch(function (response) {
      console.log(response);
    });
  }
};
